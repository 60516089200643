import React from "react";
import { css } from "@emotion/react";
import { Link, graphql } from "gatsby";
import { rhythm } from "../utils/typography";
import Layout from "../components/Layout/Layout";
import { makeStyles } from "@material-ui/core/styles";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "24px"
  }
}));

const IndexRoot = ({ data }) => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO
        title="Welcome to RavidassGuru.com"
        description="We are trying our best to collect all the information related to Ravidassia Religion and About Guru Ravidass Ji Maharaj"
      />
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div
          key={node.id}
          style={{
            padding: "10px 15px 0"
          }}
        >
          <Link
            to={node.fields.slug}
            css={css`
              text-decoration: none;
              color: inherit;
            `}
          >
            <h3
              css={css`
                margin-bottom: ${rhythm(1 / 4)};
              `}
              className={classes.title}
            >
              {node.frontmatter.title}{" "}
            </h3>
            <p>{node.excerpt}</p>
          </Link>
        </div>
      ))}
    </Layout>
  );
};

export default IndexRoot;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
